(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ethers"));
	else if(typeof define === 'function' && define.amd)
		define(["ethers"], factory);
	else if(typeof exports === 'object')
		exports["CredenzaContracts"] = factory(require("ethers"));
	else
		root["CredenzaContracts"] = factory(root["ethers"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__629__) => {
return 