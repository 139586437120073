import { ethers } from 'ethers'
import { CDNProviders } from '@src/CredenzaContracts.enums'
import type { TCredenzaContractOpts, TFetchedContract } from '@src/CredenzaContracts.types'
import fetch from 'cross-fetch'

export const fetchCredenzaContractJson = async (opts: Pick<TCredenzaContractOpts, 'cdn' | 'name' | 'cache'>) => {
  const cdnProviderLink = opts.cdn === CDNProviders.JSDELIVR ? 'https://cdn.jsdelivr.net/npm' : 'https://unpkg.com'
  const response = await fetch(`${cdnProviderLink}/@credenza-web3/contracts/artifacts/${opts.name}.json`, {
    cache: opts.cache === false ? 'no-cache' : 'default',
  })
  const json = await response.json()
  return json as TFetchedContract
}

export const getCredenzaContract = async (opts: TCredenzaContractOpts): Promise<ethers.Contract> => {
  const abi =
    opts.abi ||
    (
      await fetchCredenzaContractJson({
        name: opts.name,
        cdn: opts.cdn,
        cache: opts.cache,
      })
    ).abi
  const providerOrSigner = opts.provider ?? opts.signer ?? opts.wallet
  const contract = new ethers.Contract(opts.address, abi, providerOrSigner)
  return contract as typeof contract
}

export const listContracts = async (
  cdnProvider: TCredenzaContractOpts['cdn'] = CDNProviders.UNPKG,
): Promise<string[]> => {
  if (cdnProvider === CDNProviders.UNPKG) {
    const unpkgUrl = 'https://unpkg.com/@credenza-web3/contracts/artifacts/?meta'
    const result = await fetch(unpkgUrl)
    const json = await (<any>result.json())
    const matchReg = /\/?(\w+)\./
    return json.files.map((file: any) => {
      const match = file.path.match(matchReg)
      return match[1]
    })
  }

  const jsdelivrUrl = 'https://data.jsdelivr.com/v1/package/npm/@credenza-web3/contracts'
  const versionResponse = await fetch(jsdelivrUrl)
  const versionData = await (<any>versionResponse.json())
  const response = await fetch(`${jsdelivrUrl}@${versionData.versions[0]}`)
  const json = await (<any>response.json())
  const artifactsDir = json.files.find((file: any) => file.name === 'artifacts' && file.type === 'directory')
  return artifactsDir.files.map((file: any) => file.name.split('.')[0])
}
